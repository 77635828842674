<template>
    <mega-modal ref="modal-2" class="modal-sm"
                :active="modal"
                :center="true"
                @onClose="modal = false">

        <mega-header :title="'Delete image'" class="bg-danger text-white">
            <span class="text-muted d-block">Confirm that you are sure you want to delete the image:</span>
            <span class="text-muted strong">"{{name}}"</span>
        </mega-header>

        <div class="card-body">
            <div class="navbar sm-down:flex-column-reverse md-up:justify-content-end">
                <mega-button class="bg-white sm-down:w-100 md-up:mr-2" @click="modal = false">Close</mega-button>
                <mega-button class="bg-danger sm-down:w-100 sm-down:mb-3" @click="submit">DELETE</mega-button>
            </div>
        </div>
    </mega-modal>
</template>

<script>
  export default {
    props: ['name'],
    data() {
      return {
        modal: false,
        id: undefined
      }
    },
    methods: {

      submit() {
        this.$api.v2.delete('/refImage', {params: {idt_ref_image: this.id}})
          .then(() => {
            this.close();
            this.$parent.close();
          })
      },

      open(id) {
        this.id = id;
        this.modal = true;
      },
      close() {this.modal = false;}
    }
  }
</script>